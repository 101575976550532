.details-table {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.details-table-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.line {
  margin-right: -1rem;
  margin-left: -1rem;
  margin-top: 0px;
  margin-bottom: 1rem;
  border-top: 1px solid #e2e2e2;
}

.search-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.search-container {
  width: 100%;
  min-height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 9px;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.boom {
  border: 1px solid;
}

/* form css */

/* input form css change when absloute neccessary, use dangerouslySetHTML instead */
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.details-table-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 3rem;
}

.details-table-form .form-group label {
  font-size: 1rem;
  /* line-height: 34px; */
  color: #294962;
  font-family: "AvenirNext-Regular", sans-serif;
  margin-bottom: 10px;
}

.details-table-form .form-group input {
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 3px #0022a343; */
  /* border: 0.5px solid #a1a1ff; */
  box-shadow: none;
  border: none !important;
  border-radius: 8px;
  font-family: "AvenirNext-Regular", sans-serif;
}

.details-table-search-customer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 3rem;
}
