.promotion-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #F5F5FB 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  /* padding-bottom: 4rem; */
}

.promotion-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.promotion-tab {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "Barlow-Medium", sans-serif;
  cursor: pointer;
}
