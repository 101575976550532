.create-promotion-step-container {
  width: 100%;
  min-height: 15.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 11px 11px 16px #00000014;
  border-radius: 16px;
  opacity: 1;
  padding: 1rem;
}

.create-promotion-step-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.create-promotion-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 1rem;
  margin-top: 2rem;
}

.create-promotion-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}

.create-promotion-form .form-group label {
  font-size: 1rem;
  color: #294962;
  font-family: "Barlow-SemiBold", sans-serif;
  margin-bottom: 10px;
}

.form-read-only {
  background: #d1d1e5 0% 0% no-repeat padding-box;
}

.create-promotion-form .form-group input {
  height: 35px;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Barlow-SemiBold", sans-serif;
}

.product-selection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
}

.product-selection h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.2rem;
  font-family: "Barlow-SemiBold", sans-serif;
}

.create-promotion-add-item-search-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 3rem;
}

.create-promotion-add-item-search-box img {
  padding-left: 0.25rem;
}

.create-promotion-add-category {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-end;
  row-gap: 0.5rem;
}

.create-promotion-add-more-categories {
  text-align: left;
  letter-spacing: 0px;
  color: #6c3bff;
  opacity: 1;
  font-size: 12px;
}

.other-store-promotion {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  column-gap: 0.5rem;
  cursor: pointer;
}

.other-store-promotion h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.2rem;
  font-family: "Barlow-SemiBold", sans-serif;
  margin-bottom: 0;
}

.create-promotion-save-button {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
}

button.btn-create-promotion-save {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  margin: 1rem;
  border-radius: 36px;
  opacity: 1;
  border: none;
  color: #ffffff;
  width: 328px;
  height: 72px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  cursor: pointer;
}

button.btn-create-promotion-cancel {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  margin: 1rem;
  opacity: 1;
  color: #5c5c66;
  width: 328px;
  height: 72px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #d1d1e5;
  border-radius: 12px;
  opacity: 1;
  cursor: pointer;
}

.select-store-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bulk-file-upload-section {
  width: 70%;
  min-height: 164px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px dashed #d1d1e5;
  border-radius: 6px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 1rem;
}

.uplaod-img {
  width: 100%;
}

.upload-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.upload-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 60%;
}

.upload-header h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-size: 1rem;
}

.upload-header p {
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
  font-size: 12px;
}

.upload-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex-basis: 40%;
}

button.btn-browse-file {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;

  margin: 1rem;

  border: none;
  color: #ffffff;

  width: 176px;
  height: 40px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

button.btn-download-file {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  width: 176px;
  height: 40px;
  margin: 1rem;

  color: #6c3bff;
  opacity: 1;

  border: 2px solid #6c3bff;
  border-radius: 6px;
  opacity: 1;
}
