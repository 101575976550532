.create-store-container {
  width: 100%;
  min-height: 5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
}

.create-store-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-Medium", sans-serif;
  font-size: 1.313rem;

  margin-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}

.create-store-details-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 1.5rem; */
}

.create-store-store-details {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-Medium", sans-serif;
  font-size: 1rem;
}

.create-store-form-structure {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 1rem;
  margin-top: 1rem;
}

.create-store-form-structure .form-group label {
  font-size: 1rem;
  color: #294962;
  font-family: "Barlow-Medium", sans-serif;
  margin-bottom: 1rem;
}

.create-store-form-structure .form-group input {
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  box-shadow: none;
  border: 1px solid #d1d1e5;
  font-family: "Barlow-Medium", sans-serif;
}

.create-store-registration-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}

.create-store-registration-form-validation-error {
  width: 100%;
  height: 28px;
  text-align: left;
  font-size: 10px;
  font-family: "AvenirNext-Regular", sans-serif;
  letter-spacing: 0px;
  color: #ff4747;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

.form-line {
  width: 100%;
  margin-top: 2rem;
  border-bottom: 1px solid #e2e2e2;
}

.create-store-standard-hours-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.create-store-standard-hours-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.create-store-standard-hours-header h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-Bold", sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
}

.create-store-standard-hours-header p {
  text-align: left;
  letter-spacing: 0px;
  color: #5c5c66;
  opacity: 1;

  font-family: "Barlow-Bold", sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0;
}

.create-store-break-time {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
}

.break-time-header {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: "Barlow-Bold", sans-serif;
  font-size: 1rem;
}

.break-time-section {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  /* margin-top: 1rem; */
}

.break-toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-Bold", sans-serif;
  font-size: 0.875rem;
  padding: 5px 0;
}

.break-toggle img {
  width: 25%;
  min-height: auto;
}

.create-store-store-instruction {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
}

.store-instruction-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-Bold", sans-serif;
  font-size: 0.875rem;
}

.store-instruction-sub-header {
  text-align: left;
  letter-spacing: 0px;
  color: #5c5c66;
  opacity: 1;
  font-family: "Barlow-Bold", sans-serif;
  font-size: 0.75rem;
}

.text-area-cumstom {
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 1rem;
  padding: 1rem;

  font-size: 1rem;
  font-family: "Barlow-Bold", sans-serif;

  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 16px;
  opacity: 1;

  resize: none;

  text-align: left;
  letter-spacing: 0px;
  color: #5c5c66;
}

.create-store-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

button.btn-create-store {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  margin: 1rem 1rem 1rem 0;
  width: 200px;
  height: 42px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

button.btn-create-store-reset {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  margin: 1rem 1rem 1rem 0;
  width: 200px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #d1d1e5;
  border-radius: 12px;
  cursor: pointer;
  opacity: 1;
}

.week-operation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  width: 50%;
}

.weekday {
  width: 40%;
}

.toggle-operations {
  width: 60%;
}
