.accept-reject-modal > .modal-dialog > .modal-content {
  width: 800px;
  height: 450px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 1rem;
  border-radius: 21px;
  opacity: 1;
}

.accept-reject-modal > .modal-dialog > .modal-content > .modal-body {
  margin: 0rem;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accept-reject-modal > .modal-dialog > .modal-content > .modal-footer {
  margin: 0rem !important;
}

.accept-reject-modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder; */
}

.accept-reject-modal-header-information {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.accept-reject-modal-header-order-id {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.accept-reject-modal-header-amount-info {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 12px;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.accept-reject-modal-header-refund-amount-info {
  text-align: left;
  letter-spacing: 0px;
  color: #f5486e;
  opacity: 1;
}

.accept-reject-modal-header-close {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
}

.accept-reject-modal-footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.accept-reject-modal-footer-accept-order {
  display: flex;
  width: 100%;
}

.accept-reject-modal-footer-reject-order {
  display: flex;
  width: 100%;
}

.modal-open-up {
  animation: modal-open-up 0.5s ease;
}

button.btn-accept-order {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  width: 200px;
  height: 42px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

button.btn-reject-order {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  width: 200px;
  height: 42px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  background: #f5486e 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
}
