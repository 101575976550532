.bulk-staff-creation-header {
  margin-bottom: 1rem;

  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-Medium", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
}

.bulk-staff-upload-section {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  min-height: 10rem;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 2px dashed #d1d1e5;
  border-radius: 12px;
  opacity: 1;
}

.bulk-staff-upload-instruction {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
}

.bulk-staff-upload-instruction-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.bulk-staff-upload-file-upload-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
}

.bulk-staff-upload-accepted-format {
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
  font-family: "Barlow-Medium", sans-serif;
  font-size: 0.7rem;
}

.bulk-staff-upload-file-upload-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  /* margin-top: 2rem; */
}

button.btn-staff-upload-browse-file {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  width: 200px;
  height: 42px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

button.btn-bulk-staff-download-sample {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  width: 200px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;

  border: 2px solid #6c3bff;
  border-radius: 6px;
  opacity: 1;
}
