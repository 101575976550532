.support-container {
  width: 100%;
  min-height: 5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  padding-bottom: 4rem;
  column-gap: 2rem;
}

.support-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.support-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.support-header {
  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 1.5rem;
}

.support-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
}

.support-text {
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
}
