.reports-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.reports-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
}

.reports-tab {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "Barlow-Medium", sans-serif;
  cursor: pointer;
}

.selected-tab {
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}
