.store-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.store-listing-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #f5f5fb 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.store-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
}

.store-table-header {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "Barlow-Medium", sans-serif;
  cursor: pointer;
}

.selected-tab {
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.line {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin-top: 0px;
  margin-bottom: 1rem;
  border-top: 1px solid #e2e2e2;
}

.store-details-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 5rem;
  column-gap: 1rem;
}
