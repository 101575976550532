.stock-overview-container {
  width: 100%;
  min-height: 15.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 11px 11px 16px #00000014;
  border-radius: 16px;
  opacity: 1;
  padding: 1rem;
}

.stock-overview-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-size: 1.2rem;
  font-family: "Barlow-Bold";
  font-weight: bolder;
  margin-bottom: 1rem;
}

.stock-overview-store-selector {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.stock-overview-search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 3rem;
}

.stock-overview-search {
  width: 100%;
  min-height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 9px;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.stock-overview-table-section {
  width: 100%;
  padding-top: 1rem;
}

.stock-overview-download-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
}

button.download {
  width: 160px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 6px;
  opacity: 1;
}
