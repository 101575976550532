.sign-in-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto !important;
  min-height: 100vh;
}

.sign-in-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.sign-in-branding-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6c3bff 0% 0% no-repeat padding-box;
  box-shadow: 3px 7px 15px #00000014;
  width: 100%;
  min-height: 31rem;
  flex-basis: 50%;
  border-radius: 2.25rem 0 0 2.25rem;
}

.brand-logo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.brand-logo img {
  width: 50%;
  min-height: auto;
}

.sign-in-form {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 7px 15px #00000014;
  width: 100%;
  min-height: 31rem;
  flex-basis: 50%;
  border-radius: 0 2.25rem 2.25rem 0;
  padding: 1rem 3rem;
  align-items: center;
}

.sign-in-content {
  width: 100%;
}

.sign-in-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 3rem;
}

.sign-in-sub-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-Medium", sans-serif;
  font-size: 1.2rem;
}

.sign-in-form-structure {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 1rem;
  margin-top: 2rem;
}

.sign-in-form-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sign-in-form-structure .form-group label {
  font-size: 1rem;
  color: #294962;
  font-family: "Barlow-Medium", sans-serif;
  margin-bottom: 1rem;
}

.sign-in-form-structure .form-group input {
  height: 35px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 9px;
  box-shadow: none;
  border: none !important;
  font-family: "Barlow-Medium", sans-serif;
}

.sign-in-forgot-password {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.75rem;
  color: #787784;
  cursor: pointer;
}

.sign-in-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

button.btn-booking-confirm {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  margin: 1rem;
  width: 100px;
  height: 42px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.sign-in-eye-position {
  position: absolute;
  left: 75%;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.form-validation-error {
  top: 636px;
  left: 977px;
  width: 306px;
  height: 28px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font-size: 14px;
  font-family: "AvenirNext-Regular", sans-serif;
  letter-spacing: 0px;
  color: #ff4747;
  opacity: 1;
  margin-top: 5px;
}

.new-account {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-account h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-Medium", sans-serif;
  font-size: 1rem;
}

.sign-up {
  color: #6c3bff;
  cursor: pointer;
}
