.upload-file-download-button {
  width: 80%;
  height: 35px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}
