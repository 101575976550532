.single-staff-creation-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-Medium", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
}

.single-staff-creation-sub-heading {
  text-align: left;
  letter-spacing: 0px;
  color: #5c5c66;
  opacity: 1;

  font-family: "Barlow-Medium", sans-serif;
  font-size: 0.875rem;
}

.staff-creation-form-structure {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 1rem;
  margin-top: 1rem;
}

.staff-creation-form-structure .form-group label {
  font-size: 1rem;
  color: #294962;
  font-family: "Barlow-Medium", sans-serif;
  margin-bottom: 0;
}

.staff-creation-form-structure .form-group input {
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  box-shadow: none;
  border: 1px solid #d1d1e5;
  font-family: "Barlow-Medium", sans-serif;
}

.single-staff-registration-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}

.single-staff-registration-form-validation-error {
  width: 100%;
  height: 28px;
  text-align: left;
  font-size: 10px;
  font-family: "AvenirNext-Regular", sans-serif;
  letter-spacing: 0px;
  color: #ff4747;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

.single-staff-location-access {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.single-staff-location-access-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-Bold", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
}

.single-staff-location-access-sub-header {
  text-align: left;
  letter-spacing: 0px;
  color: #5c5c66;
  opacity: 1;
  font-family: "Barlow-Medium", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
