.promo-card {
  width: 240px;
  min-height: 208px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 10px #3232331a;
  border-radius: 24px;
  opacity: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.promo-heading {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  font-size: 1rem;
  opacity: 1;
}

.promo-sub-heading {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  font-size: 12px;
  opacity: 1;
}

.cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 6px;
  opacity: 1;
  font-size: 1rem;
  cursor: pointer;
}
