/* .login-cred-modal > .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 257px;
} */

.login-cred-modal > .modal-dialog > .modal-content {
  width: 800px;
  height: 450px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 1rem;
  border-radius: 21px;
  opacity: 1;
}

.login-cred-modal > .modal-dialog > .modal-content > .modal-body {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-cred-header {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.modal-open-up {
  animation: modal-open-up 0.5s ease;
}

.login-cred-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  width: 100%;
}

@keyframes modal-open-up {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}

.login-cred-information-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}

.login-cred-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
}

button.btn-copy-close {
  width: 256px;
  height: 72px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
  border: none;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
