.new-order-container {
  width: 100%;
  min-height: 15.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 11px 11px 16px #00000014;
  border-radius: 16px;
  opacity: 1;
  padding: 1rem;
}

.new-order-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
  font-size: 1rem;
  /* margin-top: 1rem;*/
  margin-bottom: 1rem; 
}

.brand-credential-table-section {
  width: 100%;
  padding-top: 1rem;
}

.brand-credential-create-login {
  width: 80%;
  height: 35px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}
