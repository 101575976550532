.single-order-details-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.single-order-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.single-order-detail-table-header {
  display: flex;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-family: "Barlow-Medium", sans-serif;
  cursor: pointer;
}

.selected-tab {
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.order-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 5rem;
  column-gap: 1rem;
}

.order-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.store-information {
  display: flex;
  /* width: 20%; */
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}

.shop {
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.shop-address {
  text-align: left;
  color: #000000;
  opacity: 1;
}

.order-status {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.order-status-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
}

.order-status-tab img {
  width: 5%;
  min-height: auto;
}

.store-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25%;
}

.customer-delivery-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.delivery-partner {
  display: flex;
  width: 50%;
}

.customer-address {
  display: flex;
  width: 50%;
}

.eta-delivery {
  font-size: 12px;
  font-family: "Barlow-SemiBold", sans-serif;
  color: #f5486e;
}
