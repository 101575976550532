.refund-transaction-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.refund-transaction-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.refund-transaction-search-box {
  display: flex;
  flex-direction: row;
  width: 40%;
  column-gap: 3rem;
}

.refund-transaction-search {
  width: 100%;
  min-height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 9px;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.refund-transaction-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 60%;
}

.refund-transaction-header-filter {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.refund-transaction-filters-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}

.refund-transaction-filter-tab {
  width: auto;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.refund-transaction-individual-filter {
  font-size: 14px;
  text-align: center;
}

.refund-transaction-table-section {
  width: 100%;
  padding-top: 1rem;
}

.refund-transaction-refund-active {
  width: 80%;
  height: 35px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.refund-transaction-refund-closed {
  width: 80%;
  height: 35px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #787784 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
}
