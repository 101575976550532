.upload-file-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.upload-file-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 10rem;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 2px dashed #d1d1e5;
  border-radius: 12px;
  opacity: 1;
}

.file-upload-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;

  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 1rem;
}

.accepted-format {
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
  font-family: "Barlow-Medium", sans-serif;
  font-size: 0.7rem;
}

.file-upload-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

button.btn-browse-file {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  margin: 1rem 1rem 1rem 0;
  width: 200px;
  height: 42px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

button.btn-download-sample {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
  margin: 1rem 1rem 1rem 0;
  width: 200px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #d1d1e5;
  border-radius: 12px;
  cursor: pointer;
  opacity: 1;
}

.uploaded-files-listing {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
}

.uploaded-files-listing-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 1rem;
}

.uploaded-file-list {
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #00000014;
  border-radius: 12px;
  opacity: 1;
  padding: 1rem;
}

.uploaded-file-image img {
  width: 50%;
  min-height: auto;
}

.uploaded-file-name {
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 0.875rem;
  margin-left: -1rem;
}
