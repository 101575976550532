.brand-edit-container {
    width: 100%;
    min-height: 15.5rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 11px 11px 16px #00000014;
    border-radius: 16px;
    opacity: 1;
    padding: 1rem;
  }
  
  .brand-edit-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    font-family: "Barlow-SemiBold", sans-serif;
    font-weight: bolder;
    font-size: 1rem;
    /* margin-top: 1rem;
    margin-bottom: 1rem; */
  }
  
  .brand-edit-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 1rem;
  }
  
  .brand-edit-form .form-group label {
    font-size: 1rem;
    color: #294962;
    font-family: "Barlow-Medium", sans-serif;
    /* margin-bottom: 1rem; */
  }
  
  .brand-edit-form .form-group input {
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 9px;
    box-shadow: none;
    font-family: "Barlow-Medium", sans-serif;
  }
  
  .brand-edit-section {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5rem;
  }
  
  .brand-input-area {
    width: 100%;
    min-height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .brand-edit-form-validation-error {
    width: 100%;
    height: 28px;
    text-align: left;
    font-size: 10px;
    font-family: "AvenirNext-Regular", sans-serif;
    letter-spacing: 0px;
    color: #ff4747;
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 0;
  }
  
  .brand-edit-button {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  
  button.btn-edit-brand {
    text-align: center;
    text-transform: capitalize;
    font-size: 1rem;
    margin: 1rem;
    width: 200px;
    height: 42px;
    background: #6c3bff 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
  
  button.btn-reset {
    text-align: center;
    text-transform: capitalize;
    font-size: 1rem;
    color: #000;
    margin: 1rem;
    width: 200px;
    height: 42px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #d1d1e5;
    border-radius: 12px;
    cursor: pointer;
    opacity: 1;
  }
  
  .open-map {
    text-align: left;
    letter-spacing: 0px;
    color: #6c3bff;
    opacity: 1;
    font-size: 13px;
    cursor: pointer;
    position: absolute;
    right: 3%;
  }
  
  .company-address-map {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    justify-self: flex-start;
    position: relative;
  }
  