.staff-credential-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.staff-credential-search-box {
  display: flex;
  flex-direction: row;
  width: 40%;
  column-gap: 3rem;
}

.staff-credential-search {
  width: 100%;
  min-height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 9px;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.staff-credential-table-section {
  width: 100%;
  padding-top: 1rem;
}
