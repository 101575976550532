.sales-report-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.sales-report-dropdown {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 1rem;
}

.sales-report-store-dropdown {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
}

.sales-report-session-dropdown {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
}

.sales-report-figure-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  flex-basis: 40%;
}

.sales-report-figure {
  width: 70%;
  height: 49px;
  background: #f5f5fb 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10px;
}

.sales-report-download-button {
  width: 40%;
  height: 49px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.sales-report-table-section {
  width: 100%;
  padding-top: 1rem;
}