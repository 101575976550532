.refund-modal > .modal-dialog > .modal-content {
  width: 400px;
  height: 550px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 1rem;
  border-radius: 21px;
  opacity: 1;
}

.refund-modal > .modal-dialog > .modal-content > .modal-body {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refund-modal > .modal-dialog > .modal-content > .modal-footer {
  margin: 0rem !important;
}

.refund-modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.refund-modal-header-information {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.refund-modal-header-order-id {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.refund-modal-header-amount-info {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 12px;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.refund-modal-header-refund-amount-info {
  text-align: left;
  letter-spacing: 0px;
  color: #f5486e;
  opacity: 1;
}

.refund-modal-header-close {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  letter-spacing: 0px;
  color: #787784;
  opacity: 1;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
}

.refund-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  width: 100%;
}

.refund-information-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}

.refund-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
}

button.btn-refund-save {
  width: 256px;
  height: 72px;
  background: #6c3bff 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
  border: none;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
