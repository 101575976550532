.select-store {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1rem;
  color: #150e3d;
}

.select-store-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
  margin-bottom: 2rem;
}

.promotion-type {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  gap: 2rem;
  padding-left: 1rem;
}
