.customer-details-container {
  width: 100%;
  min-height: 15.5rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 6px #00000014;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.details-table-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.back-button {
  width: 24px;
  height: 24px;
  background: #d1d1e5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.line {
  margin-right: -1rem;
  margin-left: -1rem;
  margin-top: 0px;
  margin-bottom: 1rem;
  border-top: 1px solid #e2e2e2;
}

.customer-details-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1rem;
}

.form-information-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}

.customer-input-area {
  width: 100%;
  min-height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.customer-details-form .form-group label {
  font-size: 1rem;
  color: #294962;
  font-family: "AvenirNext-Regular", sans-serif;
  margin-bottom: 10px;
}

.customer-details-form .form-group input {
  height: 35px;
  background: #d1d1e5 0% 0% no-repeat padding-box;
  box-shadow: none;
  border: none !important;
  border-radius: 8px;
  font-family: "AvenirNext-Regular", sans-serif;
}
