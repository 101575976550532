.inventory-management-container {
  display: flex;
  flex-direction: column;
}

.inventory-management-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.inventory-management-tab {
  display: flex;
  width: 52%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "Barlow-Medium", sans-serif;
  cursor: pointer;
}

.selected-tab {
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}
