.update-inventory-container {
  width: 100%;
  min-height: 15.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 11px 11px 16px #00000014;
  border-radius: 16px;
  opacity: 1;
  padding: 1rem;
}

.update-inventory-header {
  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-size: 1.2rem;
  font-family: "Barlow-Medium";
  font-weight: bolder;
  margin-bottom: 1rem;
}

.update-inventory-store-selector {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.update-inventory-search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 3rem;
}

.update-inventory-search {
  width: 100%;
  min-height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 9px;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.update-inventory-search-results {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  align-items: flex-start;
}

.update-inventory-search-results-header {
  text-align: left;
  letter-spacing: 0px;
  color: #6c3bff;
  opacity: 1;
  margin: 0.5rem 0;
}

.update-inventory-search-results-image-url {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}

.update-inventory-search-result-image {
  width: 124px;
  height: 124px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.update-inventory-search-result-image img {
  width: 100%;
  min-height: auto;
  object-fit: contain;
}

.update-inventory-search-result-image-url {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: #150e3d;
  font-size: 1rem;
  gap: 0.5rem;
}

.update-inventory-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1rem;
}

.update-inventory-form .form-group label {
  font-size: 1rem;
  color: #294962;
  font-family: "Barlow-Medium", sans-serif;
  /* margin-bottom: 1rem; */
}

.update-inventory-form .form-group input {
  height: 35px;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  border-radius: 9px;
  box-shadow: none;
  font-family: "Barlow-Medium", sans-serif;
}

.update-inventory-input-area {
  width: 100%;
  min-height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.update-inventory-form-validation-error {
  width: 100%;
  height: 28px;
  text-align: left;
  font-size: 10px;
  font-family: "AvenirNext-Regular", sans-serif;
  letter-spacing: 0px;
  color: #ff4747;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

.update-inventory-promotion-code {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-size: 1rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.update-inventory-promotion-code-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.update-inventory-coupon-code {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  text-align: left;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  font-size: 1rem;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.update-inventory-coupon-code-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.update-inventory-form-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5rem;
}
