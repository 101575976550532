.catalog-management-container {
  display: flex;
  flex-direction: column;
}

.catalog-management-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.catalog-management-header-tabs {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.catalog-management-tab-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.catalog-management-tab-info h1 {
  width: 100%;
  text-align: center;
  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  margin-bottom: 0rem;
  line-height: 2.4rem;
}

.selected-tab-line {
  border-bottom: 4px solid #150e3d;
  border-radius: 4px;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}
