.order-management-container {
  display: flex;
  flex-direction: column;
}

.order-management-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.order-management-header-tabs {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.order-management-header-filter {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.selected-tab {
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.order-management-tab-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.order-management-tab-info h1 {
  width: 100%;
  text-align: center;
  font-family: "Barlow-SemiBold", sans-serif;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #150e3d;
  opacity: 1;
  margin-bottom: 0rem;
  line-height: 2.4rem;
}

.selected-tab-line {
  border-bottom: 4px solid #150e3d;
  border-radius: 4px;
  font-family: "Barlow-SemiBold", sans-serif;
  font-weight: bolder;
}

.order-management-filters-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}

.order-management-filter {
  width: auto;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.order-management-individual-filter {
  font-size: 14px;
  text-align: center;
}
