.view-promotion-container {
  width: 100%;
  min-height: 15.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 11px 11px 16px #00000014;
  border-radius: 16px;
  opacity: 1;
  padding: 1rem;
}

.view-promotion-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.view-promotion-search-box {
  display: flex;
  flex-direction: row;
  width: 40%;
  column-gap: 3rem;
}

.view-promotion-filters-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 60%;
  column-gap: 0.5rem;
}

.view-promotion-search {
  width: 100%;
  min-height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 9px;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.view-promotion-table-section {
  width: 100%;
  padding-top: 1rem;
}

.all-store-filter {
  width: auto;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1e5;
  border-radius: 18px;
  opacity: 1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0 0.5rem;
}

.all-store {
  font-size: 14px;
  text-align: center;
}
