table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 70%;
}

td,
th {
  border-bottom: 1px #dddddd solid;
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
}

.striped:nth-child(even) {
  background-color: #dddddd;
}
.hover:hover {
  background-color: #61dafb;
}

.table-structure {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 13px;
}

.button-space {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
